//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//	Build by: mruiz@akamai.com
//	v1.0
//	Jul 07, 2023 
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import {Clipboard} from '@angular/cdk/clipboard';
import { Postcreateuser } from '../../interface/postcreateuser'
import { ApicallsService } from '../../services/apicalls.service'
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../shared-components/dialog/dialog.component'
import { environment } from '../../../environments/environment';
import { PostEmailCreateUser } from 'src/app/interface/postemailcreateuser';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})

export class RegisterComponent implements OnInit{
	constructor(private clipboard: Clipboard, private _apicallsService: ApicallsService, private router: Router, private _dataservice: DataService, private dialog: MatDialog, private _cookieservice: CookieService) {}
	@ViewChild('myForm') myForm: NgForm | undefined;
	usernameFnLetter: string = "";
	usernameLnLetter: string = "";
	submitButton: boolean = false;
	counterChangesFn: number = 0;
	counterChangesLn: number = 0;
	checked: boolean = false;
	errorMessage: string = "";
	errorFound: boolean = false;
	successFound: boolean = false;
	successMessage: string = "";
	demoName: string = "";
	demoDescription_a: string = ""
	demoDescription_b: string = ""
	demoDescription_c: string = ""
	demoDescription_d: string = ""
	demoDescription_e: string = ""
	urlParamsString: string = ""
	demoForm = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		lastName: new FormControl('', [Validators.required]),
		userName: new FormControl(''),
		userPwd: new FormControl('', [Validators.required]),
		userEmail: new FormControl('', [Validators.required, Validators.email]),
		userRole: new FormControl('None', []),
		userCompany: new FormControl('', [Validators.required]),
	});
	demoLogin: string = ""
	createInProgress: boolean = false
	userNameAndPassword: string = "";
	accountSubGroups: Array<String> = []
	postCreateUser: Postcreateuser = {
		'firstName' : "",
		'lastName' : "",
		'userName' : "",
		'userPwd' : "",
		'userEmail' : "",
		'userRole' : "None",
		'userCompany' : "",
		'subscribe' : true,
		'enabledAccountsubGroupName' : [],
		'requested_by' : "",
		'demo_name' : ""
	}
	requested_by = ''
	demoNameReg: string = ""
	userNameCreatedDemo: string = ""
	passwordCreatedDemo: string = ""
	dialogConfig: MatDialogConfig | undefined
	show_tc_checkbox = true
	userEmail: string = ''
	objDemoUserCreated: DemoUserCreated = {
		status_code : 200,
		response: ""
	}

	postemailcreateuser: PostEmailCreateUser = {
		'email_template_name' : '',
		'email_template_values' : [],
		'from_string' : '',
		'receivers' : [],
		'sender' : '',
		'subject' : '',
		'to_string' : ''
	}

	setDemoName: string="";

	ngOnInit() {
		try{
			this.userEmail = this._cookieservice.get('user_email');
			if(!this.userEmail){
				this.userEmail = environment.requested_by
			}
		}
		catch{
			this.userEmail = environment.requested_by
			console.error('No \'user_email\' cookie found!');
		}
		console.log('environment::'+JSON.stringify(environment))
		console.log('this.userEmail::'+this.userEmail)
		this.dialogConfig = new MatDialogConfig();
		this.dialogConfig.disableClose = true
		// console.log(this.checked)
		const currentUrl = window.location.search;
		// const urlParams = new URLSearchParams(currentUrl);
		const urlParams = new URLSearchParams(currentUrl);
		const demoName = urlParams.get('demo')
		this.urlParamsString = demoName as string
		
		if(demoName == "gdc"){
			this.demoName = "Guardicore";
			this.demoDescription_a = "Fill out the form to provision an interactive demo for your customer to see firsthand why Guardicore provides the simplest, fastest, and most intuitive way to enforce Zero Trust principles."
			this.demoDescription_b = "The demo will showcase how Guardicore:"
			this.demoDescription_c = "Reduces customer's attack surface"
			this.demoDescription_d = "Prevents lateral movement"
			this.demoDescription_e = "Secures critical IT assets"
			this.demoForm.controls['userName'].setValue("gc-");
			// this.demoForm.controls['userPwd'].setValue("no_password_requried")
			this.accountSubGroups = []
			this.demoNameReg = demoName
			this.show_tc_checkbox = false
			this.checked = true
			this.demoLogin = "https://interactive-demos.akamaicentral.com/login?demo=gdc"
		}
		
		if(demoName == "asm"){
			this.demoName = "App & API Protector with ASM";
			this.demoDescription_a = "Fill out the form to provision an interactive demo for your customer to see firsthand why App & API Protector with ASM quickly identifies vulnerabilities and mitigates threats across your entire web and API estates."
			this.demoDescription_b = "The demo will showcase how App & API Protector with ASM:"
			this.demoDescription_c = "Stops threats at the edge and inspected requests in real time"
			this.demoDescription_d = "Leverages cyber intelligence, machine learning models, and human verification to protect against future threats"
			this.demoDescription_e = "Hands-off updates, self-tuning adjustments, and auto API discovery continuously modernize your security stance and simplify the effort"
			// this.urlParamsString = ""
			this.demoForm.controls['userName'].setValue("asmdemo-");
			// this.accountSubGroups = ["KSD Sandbox", "ASM Sandbox", "Malware Protector Demo"]
			this.accountSubGroups = ["Malware Protector Demo"]
			this.demoNameReg = demoName
			this.demoLogin = "https://interactive-demos.akamaicentral.com/login?demo=asm"
		}
		
		if(demoName == "aap"){
			this.demoName = "App & API Protector";
			this.demoDescription_a = "Fill out the form to provision an interactive demo for your customer to see firsthand why App & API Protector is simpler, faster, and more cost-effective choice."
			this.demoDescription_b = "The demo will showcase how App & API Protector:"
			this.demoDescription_c = "Tailors defenses to the latest applications and threats"
			this.demoDescription_d = "Simplifies security with automated updates and self-tuning "
			this.demoDescription_e = "Empowers developers and security teams"
			this.demoForm.controls['userName'].setValue("aapdemo-");
			// this.demoForm.controls['userPwd'].setValue("no_password_requried")
			this.accountSubGroups = ["Test"]
			this.demoNameReg = demoName
			this.demoLogin = "https://interactive-demos.akamaicentral.com/login?demo=aap"
		}
		// if(demoName == "apisec"){
		// 	this.demoName = "API Security";
		// 	this.demoDescription_a = "Fill out the form to provision an interactive demo to see firsthand how API Security gives you full visibility into the entire API estate through continuous discovery and monitoring."
		// 	this.demoDescription_b = "The demo will showcase how API Security:"
		// 	this.demoDescription_c = "Continuous deep API discovery generates an updated API inventory and risk audit"
		// 	this.demoDescription_d = "Big data AI and behavioral analytics detect and alert for anomalies in the API estate"
		// 	this.demoDescription_e = "Automated responses via written policies push actions to inline components, such as Jira, texts, or emails"
		// 	this.demoForm.controls['userName'].setValue("apisec-");
		// 	// this.demoForm.controls['userPwd'].setValue("no_password_requried")
		// 	this.accountSubGroups = []
		// 	this.demoNameReg = demoName
		// }
		if(demoName == "apisec"){
			this.demoName = "API Security";
			this.demoDescription_a = "Fill out the form to provision an interactive demo to see firsthand how Akamai API Security gives you full visibility into the entire API estate through continuous discovery and monitoring."
			this.demoDescription_b = "The demo will showcase how Akamai API Security:"
			this.demoDescription_c = "Identifies how many APIs you have regardless of type or configuration."
			this.demoDescription_d = "Discovers the types of sensitive data APIs can access and the number of users who have accessed sensitive data over your APIs."
			this.demoDescription_e = "Discovers the attack paths hackers can exploit without any integrations, installations, or implementations and eliminate weaknesses before they are exploited."
			this.demoForm.controls['userName'].setValue("apisec-");
			// this.demoForm.controls['userPwd'].setValue("no_password_requried")
			this.accountSubGroups = []
			this.demoNameReg = demoName
			this.demoForm.controls['userPwd'].setValue('None')
			this.demoLogin = "https://se-demo.nonamesec.com/login"
		}
		if(demoName == "cpro"){
			this.demoName = "Content Protector";
			this.demoDescription_a = "Fill out the form to provision an interactive demo to see how to stop scrapers with tailored detections and protect your web content and revenue potential."
			this.demoDescription_b = "The demo will showcase how Akamai Content Protector:"
			this.demoDescription_c = "Can stop competitors from using your own content to harm you."
			this.demoDescription_d = "Improves conversion rates by removing scraper bots that are slowing down your site and apps."
			this.demoDescription_e = "Can stop the relentless scraping attacks by counterfeiters that impact your reputation and your SEO rankings."
			this.demoForm.controls['userName'].setValue("cprodemo-");
			// this.demoForm.controls['userPwd'].setValue("no_password_requried")
			this.accountSubGroups = ["Demo Group"]
			this.demoNameReg = demoName
			this.demoForm.controls['userPwd'].setValue('None')
			this.demoLogin = "https://control.akamai.com/apps/auth/#/login"
		}
		console.log('demoNameReg::'+this.demoNameReg)
	}
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//To format username when firstName input changes
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	changefirstname(event: any){
		this.counterChangesFn = this.counterChangesFn + 1;
		console.log("Changes detected:"+this.counterChangesFn);
		this.usernameFnLetter = event.target.value;
		
		if(this.usernameFnLetter){
			
			if((this.counterChangesFn == 1)){
				
				if(this.demoForm.controls['lastName'].value){
					const firstLetter = Array.from(this.usernameFnLetter)[0].toLowerCase();
					const usernameCurrrent = this.demoForm.controls['userName'].value
					
					if(usernameCurrrent){
						var userName = usernameCurrrent.substring(0, usernameCurrrent.indexOf('-')+1)+firstLetter;
						// console.log('PPP')
						// console.log(userName)
						this.demoForm.controls['userName'].setValue((userName+this.demoForm.controls['lastName'].value).replace(/\s/g, "").toLowerCase());
					}
				}
				
				else{
					const firstLetter = Array.from(this.usernameFnLetter)[0].toLowerCase();
					const setUsernameLetter = this.demoForm.controls['userName'].value + firstLetter
					this.demoForm.controls['userName'].setValue(setUsernameLetter.replace(/\s/g, "").toLowerCase());
					console.log(setUsernameLetter);
				}				
			}
		}
		
		else{
			this.counterChangesFn = 0
			
			if(this.demoForm.controls['userName'].value){
				const string = this.demoForm.controls['userName'].value;
				var userName = string.substring(0, string.indexOf('-'));
				this.demoForm.controls['userName'].setValue(userName+"-")
			}
		}
	  }
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//To format username when lastName input changes
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	changelastname(event: any){
		this.counterChangesLn = this.counterChangesLn + 1;
		// console.log("Changes detected:"+this.counterChangesLn);
		this.usernameLnLetter = event.target.value;
		
		if(this.usernameLnLetter){
			
			if((this.counterChangesLn >= 1)){
				const string = this.demoForm.controls['userName'].value;
				
				if(string){
					var userName = string.substring(0, string.indexOf('-')+2);
					console.log(userName)
					this.demoForm.controls['userName'].setValue((userName+this.usernameLnLetter).replace(/\s/g, "").toLowerCase())
				}
			}			
		}
		
		if((!this.demoForm.controls['lastName'].value) && (!this.demoForm.controls['firstName'].value)){
			// console.log("LastName Empty")
			const string = this.demoForm.controls['userName'].value;
			
			if(string){
				var userName = string.substring(0, string.indexOf('-'));
				console.log(userName)
				this.demoForm.controls['userName'].setValue(userName+'-'.toLowerCase())
			}
		}
		
		if((!this.demoForm.controls['lastName'].value) && (this.demoForm.controls['firstName'].value)){
			// console.log("LastName Empty")
			const string = this.demoForm.controls['userName'].value;
			
			if(string){
				var userName = string.substring(0, string.indexOf('-')+1)+Array.from(this.demoForm.controls['firstName'].value)[0].toLowerCase();
				this.demoForm.controls['userName'].setValue(userName.replace(/\s/g, "").toLowerCase())
			}
		}
		
		if((this.demoForm.controls['lastName'].value) && (!this.demoForm.controls['firstName'].value)){
			// console.log("LastName Empty")
			const string = this.demoForm.controls['userName'].value;
			
			if(string){
				var userName = string.substring(0, string.indexOf('-')+1)+this.demoForm.controls['lastName'].value.toLowerCase();
				this.demoForm.controls['userName'].setValue(userName.replace(/\s/g, "").toLowerCase())
			}
		}
	}
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//When SUBMIT button is clicked
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	onSubmit(){
		
		if((this.demoForm.valid) && (this.checked)) {

			let user_passwd = this.demoForm.controls['userPwd'].value as string
			let user_username = this.demoForm.controls['userName'].value as string
			
			if(this.demoNameReg === "apisec"){
				user_passwd = 'none'
				user_username= this.demoForm.controls['userEmail'].value as string
			}

			if(this.demoNameReg === "cpro"){
				user_passwd = 'none'
			}
			
			this.createInProgress = true

			this.postCreateUser = {
				'firstName' : this.demoForm.controls['firstName'].value as string,
				'lastName' : this.demoForm.controls['lastName'].value as string,
				'userName' : user_username.toLowerCase(),
				'userPwd' : user_passwd,
				'userEmail' : this.demoForm.controls['userEmail'].value as string, 
				'userRole' : "None",
				'userCompany' : this.demoForm.controls['userCompany'].value as string,
				'subscribe' : this.postCreateUser['subscribe'],
				'enabledAccountsubGroupName' : this.accountSubGroups,
				'requested_by' : this.userEmail,
				'demo_name' : this.demoNameReg
			}
			console.log('Form is VALID...Sending request...');
			console.log(JSON.stringify(this.postCreateUser));
			this.demoCreateUser(this.postCreateUser);
			this._dataservice.setDemoName(this.demoNameReg);
		}
	}
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//To create a new demo user
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	demoCreateUser(postCreateUser: Postcreateuser){
		const testAkamaiEmail: RegExp = /@akamai.com/;
		const demoRegex: RegExp = /^ok_create_user::username::(.*)::password::(.*)/
		const demoResponse: RegExp = /^ok_create_user(.*)/

		//Added to match APISEC response from backend
		const demoResponseApiSec: RegExp = /^"ok_create_user::{\\"uiUserName\\":\s+(.*),\s+\\"userEmail\\".*\\"userPassword\\":\s+(.*)}"/
		
		if(this.demoNameReg !== "apisec"){
			if(testAkamaiEmail.test(this.demoForm.controls['userEmail'].value as string)){
				this.errorFound = true
				this.errorMessage = "Cannot use an Akamai email when creating an Interactive Demo user!"
				this.createInProgress = false
			}
		}		
		console.log('this.errorFound::'+this.errorFound)
		if(this.errorFound === false){
			console.log('calling::createDemoUser()')
			
			// this._apicallsService.getdemoUserCreatedResponse.subscribe({ 	// Uncomment To simulate response from /api/v1/demos/set/user (for APISEC)
			this._apicallsService.createDemoUser(postCreateUser).subscribe({
				next: data => {
					console.log('Server response:', JSON.stringify(data.response)); 
					// if((data.response != 'ok_create_user') || (data.status_code != 200)){
					if((!demoResponse.test(data.response)) || (data.status_code != 200)){
						this.createInProgress = false
						this.errorFound = true
						this.errorMessage = data.response
					}
					
					else{						
						this.errorFound = false;
						this.successFound = true;				

						if(this.demoNameReg === "cpro"){
							const regex_response_cpro = data.response.match(demoRegex)
							let cpro_username = regex_response_cpro[1].toLowerCase()
							let cpro_password = regex_response_cpro[2]
							// console.log('cpro_username'+cpro_username)
							// console.log('cpro_password'+cpro_password)
							this._dataservice.changeUserName(cpro_username)
							this._dataservice.changeUserPassword(cpro_password)
							this._dataservice.changeUserEmail(cpro_username)
							this.clipboard.copy("username: "+cpro_username+"\npassword: "+cpro_password+"\nlogin: https://control.akamai.com/apps/auth/#/login")
							// this.userNameAndPassword = "username: "+this.demoForm.controls['userName'].value+"\npassword: "+this.demoForm.controls['userPwd'].value;
						}

						else if(this.demoNameReg === "apisec"){
							console.log('apisec::'+JSON.stringify(data.response))
							let createResponse = JSON.stringify(data.response)
							const regex_response = createResponse.match(demoResponseApiSec)

							if(regex_response){
								let regex_response_user = regex_response[1].replaceAll('\\"', '')
								let regex_response_pwd = regex_response[2].replace('\\\\\\', '')
								regex_response_pwd = regex_response_pwd.replace('\\"', '')
								regex_response_pwd = regex_response_pwd.replace('\\"', '')
								console.log('regex_response_user::'+regex_response_user)
								console.log('regex_response_pwd::'+regex_response_pwd)
								this._dataservice.changeUserName(regex_response_user)
								this._dataservice.changeUserPassword(regex_response_pwd)
								this._dataservice.changeUserEmail(regex_response_user)
								this.clipboard.copy("username: "+regex_response_user+"\npassword: "+regex_response_pwd+"\nlogin: https://se-demo.nonamesec.com/login")

							}
							
						}
						//Added to match remaining demo response from backend
						else{
							const regex_response_demo = data.response.match(demoRegex)
							let demo_username = regex_response_demo[1].toLowerCase()
							let demo_password = regex_response_demo[2]
							this._dataservice.changeUserName(demo_username)
							this._dataservice.changeUserPassword(demo_password)
							this._dataservice.changeUserEmail(this.demoForm.controls['userEmail'].value as string)
							this.successMessage = "Success! Username and password created!"
						}

						this.dialog.open(DialogComponent, this.dialogConfig);
						this.demoForm.reset();
						this.myForm?.resetForm();
						this.checked = false
						
						const currentUrl = window.location.search;
						const urlParams = new URLSearchParams(currentUrl);
						const demoName = urlParams.get('demo')
						this.urlParamsString = demoName as string
						
						if(demoName == "gdc"){
							this.demoName = "Guardicore";
							this.demoForm.controls['userName'].setValue("gc-");

						}
						
						if(demoName == "asm"){
							this.demoName = "APP & API with ASM";
							this.demoForm.controls['userName'].setValue("asmdemo-");
						}
						
						if(demoName == "aap"){
							this.demoName = "APP & API";
							this.demoForm.controls['userName'].setValue("appdemo-");
						}
						
						if(demoName == "apisec"){
							this.demoName = "API Security";
							this.demoForm.controls['userName'].setValue("apisec-");
							this.demoForm.controls['userPwd'].setValue('None')
						}

						if(demoName == "cpro"){
							this.demoName = "Content Protector";
							this.demoForm.controls['userName'].setValue("cprodemo-");
							this.demoForm.controls['userPwd'].setValue('None')
						}
					}
				},
				error: err => {
					console.log('Error from server:', err);
					this.errorMessage =  err
					this.createInProgress = false
				},
				complete: () => {
					
					if(this.successFound){
						this.createInProgress = false
						// this.clipboard.copy("username: "+this.demoForm.controls['userName'].value+"\npassword: "+this.demoForm.controls['userPwd'].value)
						this.demoForm.controls['userName'].setValue("asmdemo-");
						
						if(this.demoNameReg == 'gdc'){
							this.demoForm.controls['userName'].setValue("gc-");
						}
						
						if(this.demoNameReg == 'aap'){
							this.demoForm.controls['userName'].setValue("aapdemo-");
						}
						
						if(this.demoNameReg == 'apisec'){
							this.demoForm.controls['userName'].setValue("apisec-");
							this.demoForm.controls['userPwd'].setValue('None')
						}

						if(this.demoNameReg == 'cpro'){
							this.demoForm.controls['userName'].setValue("cprodemo-");
						}
					}
				},
			});


				
			// this._apicallsService.createDemoUser(postCreateUser).subscribe({
			// 	next: data => {
			// 		console.log('Server response:', data); 
			// 		// if((data.response != 'ok_create_user') || (data.status_code != 200)){
			// 		if((!demoResponse.test(data.response)) || (data.status_code != 200)){
			// 			this.createInProgress = false
			// 			this.errorFound = true
			// 			this.errorMessage = data.response
			// 		}
					
			// 		else{						
			// 			this.errorFound = false;
			// 			this.successFound = true;
						
			// 			// this.userNameCreatedDemo = this.demoForm.controls['userName'].value as string
			// 			// this.passwordCreatedDemo = this.demoForm.controls['userPwd'].value as string
			// 			// this.userNameAndPassword = "username: "+this.demoForm.controls['userName'].value+"\npassword: "+this.demoForm.controls['userPwd'].value;
			// 			// this.successMessage = "Success! Username and password created!"
			// 			// this._dataservice.changeUserName(this.demoForm.controls['userName'].value as string)
			// 			// this._dataservice.changeUserPassword(this.demoForm.controls['userPwd'].value as string)
			// 			// this._dataservice.changeUserEmail(this.demoForm.controls['userEmail'].value as string)

			// 			const regex_response_demo = data.response.match(demoRegex)
			// 			let demo_username = regex_response_demo[1].toLowerCase()
			// 			let demo_password = regex_response_demo[2]
			// 			this._dataservice.changeUserName(demo_username)
			// 			this._dataservice.changeUserPassword(demo_password)
			// 			this._dataservice.changeUserEmail(this.demoForm.controls['userEmail'].value as string)
			// 			this.successMessage = "Success! Username and password created!"

			// 			if(this.demoNameReg === "cpro"){
			// 				const regex_response_cpro = data.response.match(demoRegex)
			// 				let cpro_username = regex_response_cpro[1].toLowerCase()
			// 				let cpro_password = regex_response_cpro[2]
			// 				// console.log('cpro_username'+cpro_username)
			// 				// console.log('cpro_password'+cpro_password)
			// 				this._dataservice.changeUserName(cpro_username)
			// 				this._dataservice.changeUserPassword(cpro_password)
			// 				this._dataservice.changeUserEmail(cpro_username)
			// 				this.clipboard.copy("username: "+cpro_username+"\npassword: "+cpro_password+"\nlogin: https://control.akamai.com/apps/auth/#/login")
			// 				// this.userNameAndPassword = "username: "+this.demoForm.controls['userName'].value+"\npassword: "+this.demoForm.controls['userPwd'].value;
			// 			}

			// 			else if(this.demoNameReg === "apisec"){
			// 				const regex_response = data.response.match(demoResponse)
			// 				let regex_response_user = regex_response[1].replace('::', '')
			// 				const obj_response = JSON.parse(regex_response_user)
			// 				console.log('obj_response::'+JSON.stringify(obj_response));
			// 				// this.successMessage = "Success! An invitation link has been created for "+this.demoForm.controls['userEmail'].value as string
			// 				this._dataservice.changeUserName(obj_response['userEmail'])
			// 				this._dataservice.changeUserPassword(obj_response['userPassword'])
			// 				this._dataservice.changeUserEmail(obj_response['userEmail'])

			// 				this.clipboard.copy("username: "+obj_response['userEmail']+"\npassword: "+obj_response['userPassword']+"\nlogin: https://se-demo.nonamesec.com/login")
			// 				// this.userNameAndPassword = "username: "+this.demoForm.controls['userName'].value+"\npassword: "+this.demoForm.controls['userPwd'].value;
			// 			}

			// 			this.dialog.open(DialogComponent, this.dialogConfig);
			// 			this.demoForm.reset();
			// 			this.myForm?.resetForm();
			// 			this.checked = false
						
			// 			const currentUrl = window.location.search;
			// 			const urlParams = new URLSearchParams(currentUrl);
			// 			const demoName = urlParams.get('demo')
			// 			this.urlParamsString = demoName as string
						
			// 			if(demoName == "gdc"){
			// 				this.demoName = "Guardicore";
			// 				this.demoForm.controls['userName'].setValue("gc-");

			// 			}
						
			// 			if(demoName == "asm"){
			// 				this.demoName = "APP & API with ASM";
			// 				this.demoForm.controls['userName'].setValue("asmdemo-");
			// 			}
						
			// 			if(demoName == "aap"){
			// 				this.demoName = "APP & API";
			// 				this.demoForm.controls['userName'].setValue("appdemo-");
			// 			}
						
			// 			if(demoName == "apisec"){
			// 				this.demoName = "API Security";
			// 				this.demoForm.controls['userName'].setValue("apisec-");
			// 				this.demoForm.controls['userPwd'].setValue('None')
			// 			}

			// 			if(demoName == "cpro"){
			// 				this.demoName = "Content Protector";
			// 				this.demoForm.controls['userName'].setValue("cprodemo-");
			// 				this.demoForm.controls['userPwd'].setValue('None')
			// 			}
			// 		}
			// 	},
			// 	error: err => {
			// 		console.log('Error from server:', err);
			// 		this.errorMessage =  err
			// 		this.createInProgress = false
			// 	},
			// 	complete: () => {
					
			// 		if(this.successFound){
			// 			this.createInProgress = false
			// 			// this.clipboard.copy("username: "+this.demoForm.controls['userName'].value+"\npassword: "+this.demoForm.controls['userPwd'].value)
			// 			this.demoForm.controls['userName'].setValue("asmdemo-");
						
			// 			if(this.demoNameReg == 'gdc'){
			// 				this.demoForm.controls['userName'].setValue("gc-");
			// 			}
						
			// 			if(this.demoNameReg == 'aap'){
			// 				this.demoForm.controls['userName'].setValue("aapdemo-");
			// 			}
						
			// 			if(this.demoNameReg == 'apisec'){
			// 				this.demoForm.controls['userName'].setValue("apisec-");
			// 				this.demoForm.controls['userPwd'].setValue('None')
			// 			}

			// 			if(this.demoNameReg == 'cpro'){
			// 				this.demoForm.controls['userName'].setValue("cprodemo-");
			// 			}
			// 		}
			// 	},
			// });
		}
	}
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//When CLOSE button is clicked
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	closeButton(){
		this.successFound = false
		this.errorFound = false;
	}
	loginDemo(){
		window.open("/login?demo="+this.demoNameReg, "_self");
	}
}

interface DemoUserCreated{
	status_code: Number,
	response: any
}










