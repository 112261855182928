import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageUserName = new BehaviorSubject('userName');
  private messageUserPassword = new BehaviorSubject('userPassword');
  private messageUserEmail = new BehaviorSubject('userEmail');
  private demoNameSource = new BehaviorSubject<string>("");
  
  demoName = this.demoNameSource.asObservable();
  currentUserName = this.messageUserName.asObservable()
  currentUserPassword = this.messageUserPassword.asObservable();
  currentUserEmail = this.messageUserEmail.asObservable();

  constructor() { }

  changeUserName(message: string) {
    this.messageUserName.next(message)
  }
  changeUserPassword(message: string){
    this.messageUserPassword.next(message)
  }
  changeUserEmail(message: string){
    this.messageUserEmail.next(message)
  }
  setDemoName(name: string) {
    this.demoNameSource.next(name);
  }
}  