import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Postcreateuser } from '../interface/postcreateuser'
import { Postloginuser } from '../interface/postuserlogin'
import { PostEmailCreateUser } from '../interface/postemailcreateuser'
import { Apiresponse } from '../interface/apiresponse'
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
	providedIn: 'root'
})

export class ApicallsService {

	simulatedResponse = '{"status_code":200,"response":"ok_create_user::{\\"uiUserName\\": \\"dummy@dummy.com\\", \\"userEmail\\": \\"dummy@dummy.com\\", \\"uiIdentityId\\": \\"d2020a72-7e02-4d58-88f1-421a94d82e85\\", \\"userPassword\\": \\"o~z~y\\\\\\\\{w:Q9oa3o3\\"}"}'
	private demoUserCreatedResponse = new BehaviorSubject<DemoUserCreated>(JSON.parse(this.simulatedResponse));

	getdemoUserCreatedResponse = this.demoUserCreatedResponse.asObservable()

	constructor(private http:HttpClient) { }

	httpOptions = {
		headers: new HttpHeaders(
			{ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				'Access-Control-Allow-Methods' : 'GET,PUT,OPTIONS,POST',
				'Access-Control-Request-Headers' : 'access-control-allow-methods,access-control-allow-origin,content-type'
			})
	};

	apiBackendEndpoint = environment.apiBackendUrl

	createDemoUser(postCreateUser: Postcreateuser) {
		return this.http.post<Apiresponse>(this.apiBackendEndpoint+'/api/v1/demos/set/user', JSON.stringify(postCreateUser), this.httpOptions);
	}

	checkUserLogin(postLoginUser: Postloginuser) {
		return this.http.post<Apiresponse>(this.apiBackendEndpoint+'/api/v1/demos/get/login', JSON.stringify(postLoginUser), this.httpOptions);
	}
	sendCreateUserEmail(postEmailCreateUser: PostEmailCreateUser){
		return this.http.post<Apiresponse>('https://email-sender-api-staging.a.hh.appbattery.akadns.net/api/v1/send/email', JSON.stringify(postEmailCreateUser), this.httpOptions);
	}
}


interface DemoUserCreated{
	status_code: Number,
	response: any
}

